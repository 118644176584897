<template>
  <div class="section-list-text">
    <div v-if="content.type === 'list_with_circle_number'" class="circle-list">
      <p v-for="(key, value) in content.blocks">
        <span class="num" :style="{ background: key.color }"
          ><span>{{ key.number }}</span></span
        >
        <span class="text">
          <b v-html="key.title"></b>
          <span v-html="key.description"></span>
        </span>
      </p>
    </div>

    <div
      v-if="content.type === 'list_with_circle_number_two'"
      class="circle-list-double"
    >
      <div class="item-wrapper" v-for="(key, value) in dataContent">
        <p class="text-block" v-for="secondKey in key">
          <span class="num" :style="{ background: secondKey.color }"
            ><span>{{ secondKey.number }}</span></span
          >
          <span class="text">
            <b v-html="secondKey.title"></b>
            <span v-html="secondKey.description"></span>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-list-text",
  props: {
    content: Object
  },

  data: () => ({
    dataContent: []
  }),
  beforeMount() {
    let array = [];
    this.content.blocks.forEach((el, index) => {
      array.push(el);
    });
    let size = 2;
    for (let i = 0; i < Math.ceil(array.length / size); i++) {
      this.dataContent[i] = array.slice(i * size, i * size + size);
    }
  }
};
</script>

<style lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1108px;
.section-list-text {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  @media screen and (max-width: 670px) {
    margin: 5px 0;
    flex-direction: column;
  }

  > div {
    width: 100%;
    /*@media screen and (max-width: 1024px) {*/
    /*  max-width: 500px;*/
    /*}*/
    /*@media screen and (max-width: 670px) {*/
    /*  max-width: 670px;*/
    /*}*/

    &.circle-list {
      @media screen and (max-width: 670px) {
        margin-bottom: 15px;
      }

      > p {
        display: flex;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        @media screen and (max-width: 670px) {
          &:last-child {
            margin-bottom: 0;
          }
        }

        .num {
          margin-right: 10px;
          min-width: 20px;
          min-height: 20px;
          max-width: 20px;
          max-height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #00a03b;
          border-radius: 50%;

          span {
            color: white;
            font-size: 13px;
            font-family: $fontM;
          }
        }

        .text {
          color: #545454;
          font-family: $fontR;
          font-size: 16px;
          @media screen and (max-width: 670px) {
            font-size: 14px;
          }

          b,
          strong {
            //display: inline-block;
            font-family: $fontM;
            color: #00a03b;
            font-weight: normal;
          }
        }
      }
    }

    &.circle-list-double {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 670px) {
        margin-bottom: 15px;

        flex-direction: column;
      }

      .item-wrapper {
        max-width: 1200px;
        width: 100%;

        @media screen and (max-width: 670px) {
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0;
          }
        }

        &:first-child {
          margin-right: 15px;
          @media screen and (max-width: 670px) {
            margin-right: 0;
          }
        }

        &:last-child {
          margin-left: 15px;
          @media screen and (max-width: 670px) {
            margin-left: 0;
          }
        }

        > p {
          display: flex;
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
          }

          @media screen and (max-width: 670px) {
            &:last-child {
              margin-bottom: 0;
            }
          }

          .num {
            margin-right: 10px;
            min-width: 20px;
            min-height: 20px;
            max-width: 20px;
            max-height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #00a03b;
            border-radius: 50%;

            span {
              color: white;
              font-size: 13px;
              font-family: $fontM;
            }
          }

          .text {
            color: #545454;
            font-family: $fontR;
            font-size: 16px;
            @media screen and (max-width: 670px) {
              font-size: 14px;
            }

            b,
            strong {
              //display: inline-block;
              font-family: $fontM;
              color: #00a03b;
              font-weight: normal;
            }
          }
        }
      }
    }
  }
}
</style>
